input[type='checkbox'] {
  background-color: rgb(255, 255, 255);
  outline-color: black;
  border: 2px !important;
  border-radius: 2px;
  -webkit-appearance: none;

  width: 17px;
  height: 17px;
  cursor: pointer;
  border: 1px solid black !important;
  position: relative;
  top: 5px;
  bottom: 5px;
}
input[type='checkbox']:checked {
  background: #a78bfa
    url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
    3px 3px no-repeat;
  padding: 1px;
}
