.dropdownRed {
  border: 1px solid gray !important;
  border-radius: 10px !important;
  padding: 1rem !important;
  margin-top: 1px;
}

.dropdownProfile {
  border: 2px solid #9ca3af !important;
  border-radius: 10px !important;
  padding: 1.1rem !important;
  margin-top: -2px;
}

.dropdownProfile:hover{
  border: 2px solid #8b5cf6 !important;
}

.iconChevron {
  font-size: 0.8em !important;
  position: fixed;
  right: 2px;
  color: black;
}