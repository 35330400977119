.cron_builder {
    max-width: 42rem;
    border-radius: 8px;
}

.cron_builder .nav li a {
    color: #8b5cf6;
}

.cron-builder-bg {
    background-color: #8b5cf6;
}